@import "src/app/common/utils/scss/main.scss";
.generic-error-wrapper {
    width: 290px;
    margin: 0 auto;
    text-align: center;
    margin-top: 75px;
    .genericError-img {
        img {
            height: 47px;
            width: 77px;
        }
        margin-bottom: 20px;
    }
    .error-text {
        color: $error-color;
        font-size: $base-font + 7;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 27px;
        text-align: center;
        margin-bottom: 28px;
    }
    .btn-wrap {
        text-align: center;
        .black-btn {
            height: 46px;
            width: 134px;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
}