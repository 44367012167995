.properties-table{
  color: red !important;
  tr{
    th, td{
      padding-right: 1rem;
    }    
  }
}

.properties-td-center{
  text-align: center;
}