@import "src/app/common/utils/scss/main.scss";
.form-field {
  margin-bottom: 31px;
  &:last-child {
    margin-bottom: 29px;
  }
  .field-title {
    color: $label-color;
    font-size: $base-font - 4px;
    font-weight: 300;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 7px;
    display: block;
    font-family: $font-stack;
  }
  &.password-field {
    margin-bottom: 25px;
    & input {
      -webkit-text-security: disc;
    }
  }
  .input-field {
    height: 45px;
    width: 100%;
    color: $label-color;
    padding: 20px 30px 20px 10px;
    font-size: $base-font + 2px;
    border: 1px solid $input-border-color;
    @include border-radius(4px);
    background-color: $color-white;
    font-family: $font-stack;
  }
}

.field-wrap {
  position: relative;
}

.error-wrap {
  color: $error-text;
  margin-top: 6px;
  @include pos-abs(43px, auto, auto, 0);
  font-size: $base-font - 2;
}

.search-icon {
  height: 14px;
  width: 13px;
  display: inline-block;
  margin-left: 220px;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: -5px;
  transform: translateY(-50%);
}
