@import "src/app/common/utils/scss/main.scss";
.react-calendar {
    border: 0;
    box-shadow: 0 2px 12px 0 rgba(195, 195, 195, 0.5);
    @include border-radius(4px);
    width: 425px;
    position: relative;
    padding: 35px 47px 0px;
    animation: calendar-fade;
    animation-duration: 250ms;
    transform-origin: top left;
}

@keyframes calendar-fade {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.react-date-picker__calendar {
    width: 425px;
    top: 30px !important;
    left: 0px !important;
    z-index: 4;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__tile {
    color: #333333;
    font-weight: 400;
    font-size: $base-font;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    margin-bottom: 15px !important;
    font-family: $font-stack;
    &:enabled {
        &:hover,
        &:focus {
            @include border-radius(4px);
        }
    }
}

.react-calendar__tile--now {
    background-color: transparent;
    @include border-radius(4px);
    font-weight: 400;
    font-family: $font-stack;
    padding: 0;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
    background-color: #ffd865;
    @include border-radius(4px);
    font-weight: 600;
    font-family: $font-stack;
    padding: 0;
}

.react-calendar__navigation__label {
    color: #333333;
    font-size: $base-font + 2;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: $font-stack;
    line-height: 14px;
}

.react-calendar__month-view__weekdays {
    margin-bottom: 23px;
}

.react-calendar__month-view__weekdays__weekday {
    color: #333333;
    font-size: $base-font;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    font-family: $font-stack;
    text-align: center;
    padding: 0;
    abbr {
        text-decoration: none;
    }
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 15px 5px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button[disabled] {
    background: #f4f4f7;
}

.date-range-btn {
    width: 425px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include pos-abs(auto, auto, -432px, 0);
    padding: 14px;
    background: #fff;
    box-shadow: 0 6px 12px 0 rgba(195, 195, 195, 0.5);
    border-top: 1px solid #e8ebed;
    z-index: 3;
    margin-top: 0;
    .black-btn,
    .cancel-btn {
        height: 36px;
        width: 87px;
        padding: 0;
        font-size: $base-font - 2;
        letter-spacing: 0.51px;
        line-height: 12px;
    }
    .cancel-btn {
        width: auto;
        height: auto;
        margin-right: 21px;
    }
}

.react-date-picker__wrapper {
    border: 0;
    padding-left: 20px;
    //border-bottom: 1px solid $calendar-border;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        @include pos-abs(auto, auto, 0px, 0);
        height: 1px;
        width: 100%;
        background: $calendar-border;
        opacity: 1;
    }
    &:before {
        content: '';
        @include pos-abs(auto, auto, 0px, 0);
        height: 1px;
        width: 100%;
        background: #3a3a3a;
        opacity: 0;
    }
}

.react-date-picker__clear-button,
.react-date-picker__calendar-button {
    display: none;
}

.react-date-picker__inputGroup {
    input {
        color: #979797;
        font-family: $font-stack;
        font-size: $base-font;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
    }
}

.react-date-picker--open {
    .react-date-picker__inputGroup__leadingZero {
        color: #3a3a3a;
    }
    .react-date-picker__wrapper {
        border-color: #3a3a3a;
        transition: all 0.5s;
        &:after {
            content: '';
            @include pos-abs(auto, auto, 0px, 0);
            height: 1px;
            width: 100%;
            background: #3a3a3a;
            opacity: 0;
        }
        &:before {
            content: '';
            @include pos-abs(auto, auto, 0px, 0);
            height: 1px;
            width: 100%;
            background: #3a3a3a;
            opacity: 1;
            animation: line-animate;
            animation-duration: 700ms;
        }
    }
    .react-date-picker__inputGroup {
        input {
            color: #3a3a3a;
        }
    }
}

.react-date-picker__inputGroup__input {
    cursor: pointer;
}

@keyframes line-animate {
    0% {
        transform: translateX(-112px);
    }
    100% {
        transform: translateX(0);
    }
}