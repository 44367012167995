@import "src/app/common/utils/scss/main.scss";

.app-dashboard-menu-wrapper{
  //min-width: 300px;
}

.app-dashboard-menu-title{
  color: #979797;
  font-weight: 600;
  font-family: $font-stack;
  span{
    margin-right: .12rem;
  }
  svg{
    transform: rotate(90deg);
    transition: 150ms ease;
  }
}

.app-dashboard-menu-title[data-open=true]{
  svg{
    transform: rotate(180deg);
  }
}

.app-dashboard-menu-list{
  display: none;
  overflow: hidden;
  transition: 150ms ease;
  li > a{
    font-size: 12px !important;
  }
  li:first-child > a{
    padding-top: 20px !important;
  }
  li:last-child > a{
    padding-top: 16px !important;
  }
}

.app-dashboard-menu-list[data-open=true]{
  display: block;
  min-height: 90px;
}