@import 'src/app/common/utils/scss/main.scss';
.admin-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.right-wrapper {
    margin-left:180px;
    width: calc(100% - 180px);
}