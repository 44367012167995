$breakpoints: ( mobile: 768px, tablet: 1024px, smalldesktop: 1200px);
$font-stack:'Poppins',
sans-serif;
$base-font: 14px;
$font-color:blue;
$color-black:#000000;
$color-white:#ffffff;
$label-color: #4E4E4E;
$input-border-color:#434343;
$btn-black: #FFD865;
$admin-bg:#2F2A2B;
$select-box-bg:#36333E;
$select-text:#B9B8B8;
$sidebar-bg:#FCFCFF;
$border-color:#f2f2f2;
$menu-color:#333333;
$active-status-bg:#F8F8F8;
$active-status-color:#4D4D4D;
$tabs-gradient:#424242;
$tabs-gradient-sec:#393939;
$tabs-bg:#F4F4F7;
$tabs-color:#8E8E8E;
$calendar-color:#979797;
$calendar-border:#D8D8D8;
$table-head-color:#6F6F6F;
$description-color:#979797;
$booking-bg:#FFF5F5;
$booking-border:#FFE6E3;
$delivered-bg: #FFF2E9;
$progress-border:#BAF5F0;
$progress-bg:#E9FFFD;
$delivered-color:#FFA96F;
$progress-color:#00C8B8;
$heading-color:#4C4C4C;
$user-color:#212121;
$cancel-btn:#1C1C1C;
$menu-hover :#F5F5F5;
$toast-color:#585858;
$booking-confirmation:#FF9688;
$error-color:#2D2D2D;
$drop-down-highlight:#27242F;
$error-text:#FF0000;