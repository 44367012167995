@import "src/app/common/utils/scss/main.scss";

.new-incidental {
  display: flex;
  direction: horizontal;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 40px 0px;
}

@media screen {
}

.fields-group {
  display: flex;
  direction: horizontal;
  align-items: center;
  justify-content: flex-start;
}

.incidentals-card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  .incidentals-title {
    color: $label-color;
    font-size: $base-font - 4;
    letter-spacing: 0.56px;
    line-height: 13px;
    display: block;
    text-transform: uppercase;
  }
  .incidentals-details {
    margin-right: 7px;
    .incidentals-text {
      letter-spacing: 0px;
    }
  }
  .incidentals-text {
    font-weight: 700;
    letter-spacing: 25.7px;
  }
}

.incidentals-text {
  color: $user-color;
  font-size: $base-font + 16;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  display: block;
  text-transform: capitalize;
}

.text-field-description {
  margin-bottom: 0px !important;
}
.text-field-amount {
  padding-left: 10px;
  margin-bottom: 0px !important;
}

.btn-add {
  padding-left: 10px;
  padding-top: 17px;
}

.incidentals-table-wrapper {
  padding: 0px;
}
.incidentals-delete {
  cursor: pointer;
}

.release-completed {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #41af1a;
  padding: 10px 10px 10px 10px;
  margin-bottom: 30px;
  .release-completed-text {
    color: #212121;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
  }
}

.no-incidentals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  .no-incidentals-icon {
    z-index: 2;
  }
  .no-incidentals-message {
    color: #2d2d2d;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 8px;
  }
}

.incidentals-table {
  width: 100%;
  border-collapse: collapse;
  tr {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);

    &:first-child {
      box-shadow: none;
    }
    &.odd {
      background-color: $color-white;
    }
    &.even {
      background-color: #fcfcfc;
    }
    th {
      text-align: left;
      color: $table-head-color;
      font-size: $base-font - 2;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      padding-bottom: 10px;
      span {
        cursor: pointer;
      }
      &:first-child {
        padding-left: 27px;
      }
      &:last-child {
        width: 29px;
      }
      &.incidentals-description {
        width: 170px;
      }
      &.incidentals-amount {
        width: 100px;
      }
      &.incidentals-date {
        width: 150px;
      }
      &.incidentals-action {
        width: 42px;
      }
    }
    td {
      color: $description-color;
      font-size: $base-font - 1;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
      vertical-align: middle;
      padding: 11.1px 0 10px 0;
      //border: solid $active-status-bg;
      border: 2px solid #e4e4e4;
      border-width: 1px 0 1px 0;
      &:first-child {
        padding-left: 26px;
        border-width: 1px 0 1px 1px;
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0px 2px 2px 0;
        position: relative;
      }
      .description-text {
        display: block;
        color: $menu-color;
        font-size: $base-font - 1;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 1px;
      }
      .visiter-mobile {
        display: block;
        color: $description-color;
        font-size: $base-font - 2;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 15px;
      }
      .room-number {
        color: $menu-color;
        font-size: $base-font + 2;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
      }
      .more-info {
        margin-top: 4px;
        .moreinfo-icon {
          width: 24px;
          height: 24px;
          text-align: center;
          position: relative;
          margin-top: -6px;
          display: flex;
          cursor: pointer;
          z-index: 0;
          align-items: center;
          justify-content: center;
          &.disabled {
            opacity: 0.2;
            pointer-events: none;
            cursor: not-allowed;
          }
          &.active {
            z-index: 2;
          }
        }
      }
    }
  }
}
.incidentals-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-container {
  display: flex;
  flex-direction: column;
}

.attachment-title {
  font-size: 16px;
  padding: 20px 0px 0px 10px;
}

.attachment-table {
  padding: 20px 0px 0px 10px;
}

.attachment-dropzone-container {
  font-size: 16px;
  padding: 20px 0px 0px 10px;
}

.attachment-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border: 2px solid #e4e4e4;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.attachment-dropzone:hover {
  border: 2px solid #ffd865;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fffbee;
  transition: border 0.24s ease-in-out;
}

.attachment-files {
  padding: 20px 0px 0px 10px;
}
