.gb-form-cont{
  li{
    margin-bottom: 2rem;
  }
}

.gb-form-buttons-cont{
  div{
    width: 100% !important;
    margin-bottom: 1rem;
    button{
      width: 100% !important;
    }
  }
  div + div{
    button{
      background-color: black;
      color: #fff;
    }
  }
}

.gb-link-button{
  color: hsl(7, 100%, 67%);
  text-decoration: underline;
  transition: 250ms ease;
  &:hover{
    color: hsl(7, 100%, 77%);
    text-decoration: none;
  }
}

.gb-signup-success{
  color: #00C8B8;
  padding: .8rem;
  position: fixed;
  bottom: -.5rem;
  opacity: 0;
  right: .5rem;
  box-shadow: 1px 1px 16px rgba($color: #000000, $alpha: .25);
  border: 1px solid #cecece;
  transition: 250ms ease;
  background-color: #fff;
}

.gb-signup-success[data-visible=true]{
  opacity: 1;
  bottom: .5rem;
}

.gb-signup-error{
  color: red;
  padding: .8rem;
  position: fixed;
  bottom: -.5rem;
  opacity: 0;
  right: .5rem;
  box-shadow: 1px 1px 16px rgba($color: #000000, $alpha: .25);
  border: 1px solid #cecece;
  transition: 250ms ease;
  background-color: #fff;
}

.gb-signup-error[data-visible=true]{
  opacity: 1;
  bottom: .5rem;
}