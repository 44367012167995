@import "src/app/common/utils/scss/main.scss";
.dateRange-picker-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .btn-wrap {
    margin-top: -5px;
  }
}

.date-wrapper {
  color: $calendar-color;
  font-size: $base-font;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0 40px 39px 40px;
  display: inline-block;
  padding-bottom: 3px;
  letter-spacing: 0.2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {       
  margin: 0 10px 0 40px;
  width:250px;
}

.from-date {
  margin: 0 10px 0 20px;
}

.to-date {
  margin: 0 40px 0 10px;
}

.table-wrapper {
  padding: 0 40px;
}

.overlay-wrapper {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.separator {
  content: "-";
  font-size: 14px;
  background: #979797;
  height: 2px;
  width: 10px;
}

.btn-wrap {
  .black-btn {
    border: 1px solid transparent;
    @include transition(all 0.2s ease-in-out);
    &:hover {
      opacity: 0.8;
    }
    &:active {
      background-color: #edc44c;
      opacity: 1;
      box-shadow: 0 0 3px rgba(237, 196, 76, 1);
    }
  }
  &.active {
    .black-btn {
      font-size: $base-font - 4;
      padding: 5px 24px;
    }
  }
}



.pagination-wrapper {
  text-align: center;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 27px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #f9f9f9;
  border: 0.5px solid #bbbbbb;
  margin-left: -1px;
  color: #606060;
  cursor: pointer;
  height: 36px;
  width: 36px;
  font-family: $font-stack;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-right-radius: 4px;
  transform: rotate(180deg);
  border-top-right-radius: 4px;
  background: url("../../../../assets/images/caret-arrow.svg") no-repeat;
  background-size: 10px;
  background-position: center;
  font-size: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: url("../../../../assets/images/caret-arrow.svg") no-repeat;
  background-size: 10px;
  background-position: center;
  font-size: 0;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #1b4060;
  background-color: #eeeeee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  background-color: #efefef;
  border-color: #bbbbbb;
  cursor: default;
  color: #9b9b9b;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
