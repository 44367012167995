@import 'src/app/common/utils/scss/main.scss';
.calendar-icon {
    height: 14px;
    width: 13px;
    display: inline-block;
    margin-left: 6px;
    @include pos-abs(41%, auto, auto, -5px);
    @include transform(translateY(-50%));
    svg {
        height: 14px;
        width: 13px;
    }
}