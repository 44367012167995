@import "src/app/common/utils/scss/main.scss";
.no-records-wrapper {
    width: 315px;
    margin: 0 auto;
    text-align: center;
    margin-top: 75px;
    .no-record-img {
        img {
            height: 69.63px;
            width: 79px;
        }
        margin-bottom: 20px;
    }
    .no-records-heading {
        color: $error-color;
        font-family: $font-stack;
        font-size: $base-font + 10;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        margin-bottom: 8px;
    }
    .records-desc {
        color: $tabs-color;
        font-family: $font-stack;
        font-size: $base-font + 2;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    }
}