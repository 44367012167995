.app-customer-list-content{
  display: flex;
  list-style: none;
  color: gray;
  li + li{
    margin-left: 2rem;
  }
}

.customer-list-wrapper{
  .ant-table-content{
    min-height: 381px !important;
  }
}

.customer-list-table-avatar{
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  span{
    margin-left: .5rem;
  }
}

.app-dataset-header{
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  padding-right: 16px;
  ul{
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-bottom: .5rem;
      color: gray;
    }
    li + li{
      margin-left: 1.5rem;
    }
    select{
      display: block;
      font-size: 14px;
      padding: 4px;
      border: 1px solid #d8d8d8;
      background-color: #fff;
      outline: none;
    }
  }
  p{
    color: #000;
    font-weight: bold;
    text-decoration: underline;
  }
}

.data-table-total{
  display: block;
  text-align: right;
  width: 100% !important;
}

.rdt_TableCell div{
  width: 100%;
}

.app-dataset-header-button{
  padding: .5rem 2rem;
  border-radius: 2px;
  color: #fff;
  background-color: hsl(0, 0%, 23%);
  transition: 250ms ease;
  min-width: 111px;
  &:hover{
    background-color: hsl(0, 0%, 33%);
  }
  &:active{
    background-color: hsl(0, 0%, 23%);
  }
}

.app-dataset-header-restore-button{
  padding: .5rem 2rem;
  border-radius: 2px;
  color: hsl(0, 0%, 23%);
  background-color: hsl(45, 100%, 68%);
  transition: 250ms ease;
  min-width: 111px;
  &:hover{
    background-color: hsl(45, 100%, 78%);
  }
  &:active{
    background-color: hsl(45, 100%, 68%);
  }
}

.app-dataset-searchinput{
  display: block;
  border: 1px solid #d8d8d8;
  padding: 4px;
  display: flex;
  align-items: center;
  input{
    font-size: 14px;
    border: none;
  }
  img{
    height: 14px;
    border-left: 1px solid #d8d8d8;
    padding-left: 4px;
  }
}