@import "src/app/common/utils/scss/main.scss";
.more-info-wrapper {
  @include pos-abs(7px, 7px, auto, auto);
  width: 196px;
  background: $color-white;
  box-shadow: 0 2px 12px 0 rgba(195, 195, 195, 0.5);
  @include border-radius(2px);
  z-index: 1;
  opacity: 0;
  transform: scale(0.7);
  transform-origin: top right;
  animation: openPop 0.2s forwards;
}

@keyframes openPop {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.more-info-menu {
  li {
    a {
      color: $menu-color;
      font-family: $font-stack;
      font-size: $base-font - 4;
      font-weight: 600;
      letter-spacing: 0.42px;
      line-height: 14px;
      display: block;
      padding: 18px 15px 18px 22px;
      text-transform: uppercase;
      &:hover {
        background-color: $menu-hover;
      }
    }
  }
}
