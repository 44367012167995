.overlay-loader-main-cont{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: .7);
  display: none;
}

.overlay-loader-main-cont[data-visible=true]{
  display: flex;
}