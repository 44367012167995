@import "src/app/common/utils/scss/main.scss";
.overlay-wrapper {
  width: 100%;
  height: 100%;
  @include pos-fix(0, auto, auto, 0);
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.document-viewer-wrap {
  @include pos-fix(0, 0, auto, auto);
  height: 100vh;
  width: 57.6%;
  background: $color-white;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.25);
  padding: 40px 60px 48px 60px;
  
  @include transition(all 0.7s ease-in-out);
  overflow-y: auto;
  z-index: 3;
  animation-name: example2;
  animation-duration: 1s;
  
}

@keyframes example {
  from {
    @include transform(translateX(202%));
  }
  to {
    @include transform(translateX(0));
  }
}

.user-name {
  margin-bottom: 24px;
}

.room-card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  .room-details {
    margin-right: 7px;
    .guest-name {
      letter-spacing: 0px;
    }
  }
  .guest-name {
    font-weight: 700;
    letter-spacing: 25.7px;
  }
}

.guest-email {
  color: #6d6d6d;
  font-weight: 500;
  font-size: 14px;
}

.guest-header-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  .room-details {
    margin-right: 7px;
  }
}

.risk-level {
  color: $user-color;
  font-size: $base-font + 2;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  display: block;
  text-transform: capitalize;
}

.user-title {
  color: $label-color;
  font-size: $base-font - 4;
  letter-spacing: 0.56px;
  line-height: 13px;
  display: block;
  text-transform: uppercase;
}

.guest-name {
  color: $user-color;
  font-size: $base-font + 16;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  display: block;
  text-transform: capitalize;
}

.guest-details-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  .phone-number {
    width: 85px;
    .success-icon {
      margin-top: -2px;
      margin-left: 3px;
    }
  }
}

.guest-details {
  color: $user-color;
  font-size: $base-font - 1;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.photo-id {
  @include border-radius(3px);
  background-color: $color-white;
  box-shadow: 0 2px 104px 0 rgba(209, 209, 209, 0.5);
  height: 294px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;
  .image-wrapper {
    height: 266px;
    width: 419px;
    border: 1px dashed $calendar-color;
    img {
      height: 264px;
      width: 419px;
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .black-btn {
    padding: 15px 22.2px 14px;
    margin-left: 17px;
  }
}

// error msg styling
.noCredit-found {
  color: $user-color;
  font-size: $base-font - 1;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  .warning-icon {
    margin-right: 6px;
  }
}

//Responsive styling of document view section
@media only screen and (min-width: 1300px) {
  .document-viewer-wrap {
    padding: 40px 87px 28px 103px;
  }
}
// Chat styling
.sc-user-input--file-icon-wrapper {
  visibility: hidden;
}

.sc-header {
  background: #000000 !important;
}

.sc-launcher {
  background: #000000 !important;
}

.sc-header--close-button:hover {
  background: #1b1b1b !important;
}

.sc-message--avatar {
  background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7….gif);
}

.sc-new-messages-count {
  background-color: #4e8cff !important;
  font-weight: 400 !important;
  color: white !important;
}

.sc-user-input--text {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.sc-chat-window {
  z-index: 3;
}

.change-pass {
  color: $menu-color;
  font-size: $base-font - 4;
  font-weight: 600;
  letter-spacing: 0.42px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 0 -2px 0 0;
  .arrow-icon {
    margin-left: 5px;
  }
}

.tab-wrapper {
  padding: 26px 0 0px 0;
  h1 {
    padding: 0 38px;
  }
}
.tab-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tab-container {
  border: 1px solid #d5d3d3;
  padding: 20px 40px 20px 40px;
  min-height: 250px;
  min-width: 567px;
}

.tab-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  li {
    margin-right: 1px;
    position: relative;
    max-width: 150px;
    text-align: center;
    background: #e7e7ee;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    &.activeTab {
      position: relative;
      max-width: 150px;
      height: 100%;
      border-radius: 4px 4px 0px 0px;
      transition: all 0.3s ease-in-out;
      background: linear-gradient(
        45deg,
        $tabs-gradient 0%,
        $tabs-gradient-sec 100%
      );
      z-index: 2;
    }
    &.sliding-list {
      background: linear-gradient(
        45deg,
        $tabs-gradient 0%,
        $tabs-gradient-sec 100%
      );
      @include pos-abs(0, auto, auto, 0);
      height: 100%;
      width: 100%;
      z-index: 2;
    }
    .tabitem {
      color: $tabs-color;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
      padding: 10px 25.1px 9px;
      position: relative;
      text-orientation: sideways;
      text-rendering: optimizeSpeed;
    }
    .tabitem-active {
      color: $color-white;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
      padding: 10px 25.1px 9px;
      z-index: 2;
      position: relative;
      text-orientation: sideways;
      text-rendering: optimizeSpeed;
    }
  }
}

@keyframes example {
  0% {
    left: 0px;
  }
  100% {
    left: 100px;
  }
}
.close-button {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  height: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent !important;
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    @include pos-abs(0, auto, auto, 0);
    &:checked {
      + .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    @include pos-abs(0, auto, auto, 0);
    height: 26px;
    width: 26px;
    background-color: #e7e7ee;
    @include border-radius(100%);
    &:after {
      left: 10px;
      top: 7px;
      width: 6px;
      height: 11px;
      border: solid #41af1a;
      border-width: 0 3px 3px 0;
      @include transform(rotate(45deg));
    }
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.reservation-management-wrap {
  @include pos-fix(0, 0, auto, auto);
  height: 100vh;
  width: 57.6%;
  background: $color-white;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.25);
  padding: 40px 60px 48px 60px;
  // @include transform (translateX(202%));
  @include transition(all 0.7s ease-in-out);
  overflow-y: auto;
  z-index: 9999;
  animation-name: example2;
  animation-duration: 1s;
  &.active {
    // @include transform (translateX(202%));
    // @include transition(all 0.7s ease-in-out);
    //animation-name: example;
    //animation-duration: 1s;
    // animation-name: example2;
  }
}

.reservation-management-overlay-wrapper {
  width: 100%;
  height: 100%;
  @include pos-fix(0, auto, auto, 0);
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  // &:after {
  //     content: '';
  //     @include pos-abs(0, auto, auto, -100%);
  //     height: 100%;
  //     width: 100%;
  //     background: rgba(0, 0, 0, 0.5);
  // }
}

.react-confirm-alert {
  z-index: 999 !important;
}

.reservation-management-loading-overlay {
  @include pos-fix(0, 0, auto, auto);
  height: 100vh;
  width: 57.6%;
  background: rgba(255, 255, 255, 0.7);
  //box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.25);
  padding: 40px 60px 48px 60px;
  // @include transform (translateX(202%));
  @include transition(all 0.7s ease-in-out);
  overflow-y: auto;
  z-index: 99999;
  animation-name: example2;
  animation-duration: 1s;
  .loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

  }
}
