@import "src/app/common/utils/scss/main.scss";

.new-extra-charges {
  display: flex;
  direction: horizontal;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 40px 0px;
}

.select-option {
  display: flex;
  direction: horizontal;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 15px 0px;
}
.select-option-icon {
  padding-right: 5px;
  cursor: pointer;
}

.select-option-label {
  padding-top: 0px;
  cursor: pointer;
}

.select-extra-charges {
  padding: 0px 0px 20px 0px;
}

@media screen {
}

.fields-group {
  display: flex;
  direction: horizontal;
  align-items: center;
  justify-content: flex-start;
}

.extra-charges-card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  .extra-charges-title {
    color: $label-color;
    font-size: $base-font - 4;
    letter-spacing: 0.56px;
    line-height: 13px;
    display: block;
    text-transform: uppercase;
  }
  .extra-charges-details {
    margin-right: 7px;
    .extra-charges-text {
      letter-spacing: 0px;
    }
  }
  .extra-charges-text {
    font-weight: 700;
    letter-spacing: 25.7px;
  }
}

.extra-charges-text {
  color: $user-color;
  font-size: $base-font + 16;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  display: block;
  text-transform: capitalize;
}

.text-field {
  margin-bottom: 0px !important;
  width: 100%;
}
.text-field-amount {
  padding-left: 10px;
  margin-bottom: 0px !important;
}
.text-field-amount-no-padding {
  padding-left: 0px;
  margin-bottom: 0px !important;
}
.text-field-numeric {
  padding-left: 10px;
  margin-bottom: 0px !important;
}
.total-amount-container {
  padding-left: 10px;
}
.total-amount {
  top: 100px;
  height: 45px;
  width: 120px;
  color: $label-color;
  padding: 5px 10px;
  font-size: $base-font + 2px;
  border: 1px solid $input-border-color;
  @include border-radius(4px);
  background-color: $color-white;
  font-family: $font-stack;
}

.btn-item-add {
  padding-left: 10px;
}

.extra-charges-table-wrapper {
  padding: 0px;
}
.extra-charges-delete {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.release-completed {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #41af1a;
  padding: 10px 10px 10px 10px;
  margin-bottom: 30px;
  .release-completed-text {
    color: #212121;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
  }
}

.no-extra-charges {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  .no-extra-charges-icon {
    z-index: 0;
  }
  .no-extra-charges-message {
    color: #2d2d2d;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 8px;
  }
}

.extra-charges-table {
  width: 100%;
  border-collapse: collapse;
  tr {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);

    &:first-child {
      box-shadow: none;
    }
    &.odd {
      background-color: $color-white;
    }
    &.even {
      background-color: #fcfcfc;
    }
    th {
      text-align: left;
      color: $table-head-color;
      font-size: $base-font - 2;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      padding-bottom: 10px;
      span {
        cursor: pointer;
      }
      &:first-child {
        padding-left: 27px;
      }
      &:last-child {
        width: 29px;
      }
      &.extra-charges-description {
        width: 170px;
      }
      &.extra-charges-amount {
        width: 100px;
      }
      &.extra-charges-date {
        width: 150px;
      }
    }

    .admin-action {
      width: 92px;
      text-align: right;
      padding-right: 10px;
    }

    td {
      color: $description-color;
      font-size: $base-font - 1;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
      vertical-align: middle;
      padding: 11.1px 0 10px 0;
      //border: solid $active-status-bg;
      border: 2px solid #e4e4e4;
      border-width: 1px 0 1px 0;
      &:first-child {
        padding-left: 26px;
        border-width: 1px 0 1px 1px;
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0px 2px 2px 0;
        position: relative;
      }
      .description-text {
        display: block;
        color: $menu-color;
        font-size: $base-font - 1;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 1px;
      }
      .visiter-mobile {
        display: block;
        color: $description-color;
        font-size: $base-font - 2;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 15px;
      }
      .room-number {
        color: $menu-color;
        font-size: $base-font + 2;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
      }
      .more-info {
        margin-top: 4px;
        .moreinfo-icon {
          width: 24px;
          height: 24px;
          text-align: center;
          position: relative;
          margin-top: -6px;
          display: flex;
          cursor: pointer;
          z-index: 0;
          align-items: center;
          justify-content: center;
          &.disabled {
            opacity: 0.2;
            pointer-events: none;
            cursor: not-allowed;
          }
          &.active {
            z-index: 2;
          }
        }
      }
    }
  }
}
.extra-charges-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-admin-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 20px 75px;
}
.popup-admin-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-admin-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-admin-overlay {
  background: rgba(255, 255, 255, 0.9);
}
[data-popup="tooltip"].popup-admin-overlay {
  background: transparent;
}

.field-group-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 30px;
}
.field-padding-bottom {
  padding-bottom: 20px;
}
.checkbox-requires-quantity {
  padding-top: 15px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-active {
  padding-top: 15px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-component {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  height: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent !important;
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    @include pos-abs(0, auto, auto, 0);
    &:checked {
      + .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    @include pos-abs(0, auto, auto, 0);
    height: 26px;
    width: 26px;
    background-color: #e7e7ee;
    @include border-radius(100%);
    &:after {
      left: 10px;
      top: 7px;
      width: 6px;
      height: 11px;
      border: solid #41af1a;
      border-width: 0 3px 3px 0;
      @include transform(rotate(45deg));
    }
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.btn-container-row {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.icon-badge {
  background-color: #ff4646;
  font-size: 10px;
  font-weight: 400;
  padding-top: 1px;
  color: white;
  text-align: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  top: 6px; 
  left: 13px; 
}

.sc-chat-window {
  z-index: 99;
}
