@import "src/app/common/utils/scss/main.scss";
.header {
  position: fixed;
  width: 100%;
  z-index: 9;
}

.header-wrap {
  display: flex;
}

.logo-wrap {
  width: 181px;
  height: 71px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  img {
    width: 55px;
  }
}

.logo-img-head {
  margin: 2px 0 0 0;
  display: block;
}

.admin-handler {
  background: linear-gradient(180deg, $admin-bg 0%, $color-black 100%);
  padding: 13px 40px 20px 35px;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  width: calc(100% - 180px);
}

.hotel-selectbox-wrap {
  width: 221px;
  height: 34px;
  position: relative;
  select {
    -webkit-appearance: none;
    width: 100%;
    background-color: $select-box-bg;
    @include border-radius(4px);
    color: $select-text;
    font-size: $base-font - 2;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 15px;
    height: 34px;
    padding: 11px 27px 8px 12.9px;
    text-transform: capitalize;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  .dropdown-arrrow {
    @include pos-abs(50%, 13px, auto, auto);
    @include transform(translateY(-50%));
    svg {
      fill: $select-text;
    }
  }
}

.admin-name {
  color: $color-white;
  text-transform: lowercase;
  font-size: $base-font - 2;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  margin: 9px 0 0 0;
}

.logout-icon {
  display: inline-block;
  width: 15px;
  vertical-align: middle;
  margin-left: 8px;
}

.admin-notifications {
  display: flex;
  flex-direction: row;
  margin: 9px 0 0 0;
}

.admin-actions {
  display: flex;
  align-items: center;
  justify-content: right;
  flex: 1;
}

.admin-notifications-icons {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: right;

  color: $color-white;
  text-transform: capitalize;
  font-size: $base-font - 2;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  margin: 9px 0 0 0;
}

.table-wrapper {
  padding: 0 40px;
}

$options: #8e8e8e;
$optionsHover: #333333;
$title: #2d2d2d;

.nw-notifications {
  padding-right: 15px;
  .nw-icon {
    position: relative;
    width: 29px;
    box-sizing: border-box;

    .nw-image {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .nw-count {
      height: 17px;
      width: 17px;
      max-width: 17px;
      min-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -2px;
      right: 0;
      font-size: 10px;
      border-radius: 50%;
      background-color: #ff4646;
      font-weight: 400;
      color: white;
      cursor: pointer;
    }
  }

  .nw-container {
    border: 1px solid rgba(100, 100, 100, 0.4);
    width: 430px;
    font-size: 12px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;
    background-color: white;

    .nw-header {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      .nw-header-title {
        color: $title;
        font-weight: bold;
      }

      .nw-header-option {
        margin: 0 5px;
        color: $options;

        .nw-option {
          cursor: pointer;
          opacity: 0.5;
          color: #999;
        }

        &:hover {
          cursor: pointer;
          color: $optionsHover;
        }
      }
    }

    .nw-items {
      height: 400px;
      overflow-y: auto;

      .nw-card {
        display: flex;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        justify-content: space-between;
        background-color: #ffffff;
        font-size: 12px;
        cursor: pointer;
        padding: 6px;

        &:hover {
          background-color: #f4f4f7;
        }
        .nw-content {
          display: flex;
          color: black;
          align-items: center;
        }
        .nw-image {
          margin: 5px;
          img {
            height: 40px;
            border-radius: 50%;
          }
        }
        .nw-message {
          margin: 0 8px;
          .nw-text {
            color: #2d2d2d;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0px 5px 0px;
          }
          .nw-time {
            color: #999;
          }
        }
        .nw-options {
          display: flex;
          align-items: center;
          justify-content: center;
          .nw-option {
            font-size: 18px;
          }
        }
      }

      .nw-loader {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .nw-empty-notifications {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
    }

    .nw-footer {
      text-align: center;
      padding: 5px;
      color: $options;
      text-transform: capitalize;
      font-weight: bold;

      .nw-see-all {
        cursor: pointer;
        color: $options;
      }
    }
  }
}

.cw-notifications {
  padding-right: 15px;
  .cw-icon {
    position: relative;
    width: 29px;
    box-sizing: border-box;

    .cw-image {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .cw-count {
      height: 17px;
      width: 17px;
      max-width: 17px;
      min-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: -2px;
      right: 0;
      font-size: 10px;
      border-radius: 50%;
      background-color: #4e8cff;
      font-weight: 400;
      color: white;
      cursor: pointer;
    }
  }

  .cw-container {
    border: 1px solid rgba(100, 100, 100, 0.4);
    width: 430px;
    font-size: 12px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;
    background-color: white;

    .cw-header {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      .cw-header-title {
        color: $title;
        font-weight: bold;
      }

      .cw-header-option {
        margin: 0 5px;
        color: $options;

        .cw-option {
          cursor: pointer;
          opacity: 0.5;
          color: #999;
        }

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .cw-items {
      height: 400px;
      overflow-y: auto;

      .cw-card {
        display: flex;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        justify-content: space-between;
        background-color: #ffffff;
        font-size: 12px;
        cursor: pointer;
        padding: 6px;

        &:hover {
          background-color: #f4f4f7;
        }
        .cw-content {
          display: flex;
          color: black;
          align-items: center;
        }
        .cw-image {
          margin: 5px;
          img {
            height: 40px;
            border-radius: 50%;
          }
        }
        .cw-message {
          margin: 0 8px;
          .cw-text {
            color: #2d2d2d;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0px 5px 0px;
          }
          .cw-time {
            color: #999;
          }
        }
        .cw-options {
          display: flex;
          align-items: center;
          justify-content: center;
          .cw-option {
            font-size: 18px;
          }
        }
      }

      .cw-loader {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cw-empty-notifications {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
    }

    .cw-footer {
      text-align: center;
      padding: 5px;
      color: $options;
      text-transform: capitalize;
      font-weight: bold;

      .cw-see-all {
        cursor: pointer;
        color: $options;
      }
    }
  }
}
