.property-creation-cont{
  position: relative;  
}

.property-creation-form{
  width: 100%;
  padding: 0 38px;
}

.property-creation-form-section{
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
  margin-bottom: 3rem;
  //min-height: 400px !important;
  h2{
    text-align: left;
    font-size: 1.2rem;
    margin: 2.5rem 0 1.5rem;
    font-weight: 400;    
  }
}

.property-creation-form-item{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 1.5rem;
  width: 100%;

  span{
    color: rgba($color: #000000, $alpha: .5);    
    margin-bottom: 1rem;
    font-size: 16px;
    user-select: none;
  }

  span[data-required=true]{
    &::before{
      content: "* ";
      color: red;
    }
  }

  input[type="text"], textarea, input[type="number"]{
    height: 45px;
    width: 100%;
    color: #4E4E4E;
    padding: 20px 30px 20px 10px;
    font-size: 16px;
    border: 1px solid #434343;
    border-radius: 4px;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    margin-bottom: .5rem;
    outline: none !important;
    &:focus{
      border-width: 2px;
      border-color: #000000;
    }
  }

  input[type=checkbox]{
    height: 16px;
    width: 16px;
    margin-right: .3rem;
  }

  textarea{
    height: auto;
  }
  .select-cont{
    background-color: #ffffff;
    background-image: url("../../../assets/images/dropdown.svg");
    background-repeat: no-repeat;
    background-position: center right;
    border-radius: 4px;
  }
  select{
    height: 45px;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    //padding: 20px 30px 20px 10px;
    padding-left: 10px;
    background-color: transparent;
    background-image: none;
    appearance: none;
    font-size: 16px;
    border: 1px solid #434343;    
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
  }
  option: {
    color: #4E4E4E
  }
  p{
    color: red;
    font-size: 12px;
    display: none;
  }

  p[data-error=true]{
    display: block;
  }
}

.create-property-signup-success{
  color: #00C8B8;
  padding: .8rem;
  position: fixed;
  bottom: -.5rem;
  opacity: 0;
  right: 33.333333%;
  background-color: #fff;
  box-shadow: 1px 1px 16px rgba($color: #000000, $alpha: .25);
  border: 1px solid #cecece;
  transition: 250ms ease;
}

.create-property-signup-success[data-visible=true]{
  opacity: 1;
  bottom: .5rem;
}