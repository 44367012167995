@import 'src/app/common/utils/scss/main.scss';
.toast-wrapper {
    position: relative;
    text-align: center;
    @include pos-fix(auto, auto, -120px, 50%);
    @include transform(translateX(-50%));
    width: 603px;
    height: 56px;
    z-index: 3;
    padding: 12.5px 0;
    @include transition(all 0.6s ease-in-out);
    display: flex;
    align-items: center;
    justify-content: center;
    .toast-bg {
        width: 603px;
        height: 56px;
        border: 1px solid $progress-border;
        @include border-radius(3px);
        background-color: $progress-bg;
        @include pos-abs(0, auto, auto, 0);
        text-align: center;
    }
    &.error-state {
        .toast-bg {
            background-color: $booking-bg;
            border: 1px solid $booking-border;
        }
        .notification-text {
            color: $booking-confirmation;
        }
    }
    &.active {
        bottom: 70px;
    }
}

.notification-text {
    color: $progress-color;
    font-family: $font-stack;
    font-size: $base-font;
    font-weight: 600;
    letter-spacing: 0.58px;
    line-height: 21px;
    display: block;
    text-align: center;
    position: relative;
    z-index: 2;
    opacity: 1;
}

.delivery-details {
    color: $toast-color;
    font-family: $font-stack;
    font-size: $base-font - 4;
    letter-spacing: 0.42px;
    line-height: 16px;
}

.cancel-icon-wrapper {
    @include pos-abs(53%, 15px, auto, auto);
    @include transform(translateY(-50%));
    cursor: pointer;
    z-index: 2;
    opacity: 1;
}