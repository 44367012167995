@import "src/app/common/utils/scss/main.scss";
.form-field {
  margin-bottom: 31px;
  &:last-child {
    margin-bottom: 29px;
  }
  .field-title {
    color: $label-color;
    font-size: $base-font - 4px;
    font-weight: 300;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 7px;
    display: block;
    font-family: $font-stack;
  }
  &.password-field {
    margin-bottom: 25px;
    & input {
      -webkit-text-security: disc;
    }
  }
  .textarea-field {
    height: 120px;
    width: 100%;
    color: $label-color;
    padding: 5px 10px;
    font-size: $base-font + 2px;
    border: 1px solid $input-border-color;
    @include border-radius(4px);
    background-color: $color-white;
    font-family: $font-stack;
    .disabled {
      background-color: gray;
    }
  }
}

.field-wrap {
  position: relative;
}

.textarea-error-wrap {
  color: $error-text;
  margin-top: 10px;
  //@include pos-abs(43px, auto, auto, 0);
  font-size: $base-font - 2;
}
