@import 'src/app/common/utils/scss/main.scss';
.hotel-selectbox-wrap {
    width: 221px;
    height: 34px;
    position: relative;
}

.Dropdown-root {
    .Dropdown-control {
        background-color: $select-box-bg;
        @include border-radius(4px);
        height: 34px;
        padding: 8px 52px 8px 10px;
        text-transform: capitalize;
        color: $select-text;
        font-size: $base-font - 2;
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: 18px;
        border: 0;
    }
    &.is-open {
        .Dropdown-arrow {
            border: solid;
            border-width: 0px 2px 2px 0;
            @include transform(rotate(-135deg));
            @include transition(all 0.5s ease-in-out);
            top: 16px;
        }
    }
}

.Dropdown-menu {
    border: 0;
    background-color: $select-box-bg;
    margin-top: -2px;
    border-radius: 0 0 4px 4px;
}

.Dropdown-option {
    height: 38px;
    color: $select-text;
    font-size: $base-font - 2;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 18px;
    padding: 10px 10px;
    background-color: $select-box-bg;
    &:last-child {
        border-radius: 0 0 4px 4px;
    }
    &.is-selected {
        -webkit-tap-highlight-color: $drop-down-highlight !important;
        color: $select-text;
        background-color: $select-box-bg;
    }
    &:hover {
        background-color: $drop-down-highlight;
        color: $color-white;
    }
}

.Dropdown-arrow {
    border: solid $select-text;
    border-width: 0px 2px 2px 0;
    width: 6px;
    height: 6px;
    top: 11px;
    @include transform(rotate(45deg));
    background: transparent;
}