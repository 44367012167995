.stores-dashboard-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 3rem;
  padding: 26px 29px 29px 0;
  h1 {
    padding: 0 38px;
  }
}