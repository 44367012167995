@import "src/app/common/utils/scss/main.scss";
.overlayWhite {
    @include pos-fix(0, auto, auto, 0);
    height: 100%;
    width: 100%;
    background-color: rgba(232, 232, 232, 0.5);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-network-toast {
    @include pos-abs(auto, auto, 10%, 50%);
    text-align: center;
    width: 603px;
    height: 56px;
    padding: 12.5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    animation-name: toast-animate;
    animation-duration: 1s;
    .toast-bg {
        width: 603px;
        height: 56px;
        border: 1px solid $progress-border;
        @include border-radius(3px);
        background-color: $progress-bg;
        @include pos-abs(0, auto, auto, 0);
        text-align: center;
    }
    .notification-text {
        color: $progress-color;
        font-family: $font-stack;
        font-size: $base-font;
        font-weight: 600;
        letter-spacing: 0.58px;
        line-height: 21px;
        display: block;
        text-align: center;
        position: relative;
        z-index: 2;
        opacity: 1;
    }
}

@keyframes toast-animate {
    0% {
        bottom: -200px;
    }
    100% {
        bottom: 10%;
    }
}