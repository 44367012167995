.geofencing-map-main-container{
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  padding-top: 10px;
}

#map{
  height: 480px;
  width: 100%;
}

.map-info-window{
  li{
    margin-bottom: .3rem;
  }
  span{
    font-weight: bold;
  }
}