@import "src/app/common/utils/scss/main.scss";
.table {
  width: 100%;
  border-collapse: collapse;
  tr {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);
    &:first-child {
      box-shadow: none;
    }
    &.odd {
      background-color: $color-white;
    }
    &.even {
      background-color: #fcfcfc;
    }
    th {
      text-align: left;
      color: $table-head-color;
      font-size: $base-font - 2;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      padding-bottom: 10px;
      span {
        cursor: pointer;
      }
      &:first-child {
        padding-left: 27px;
      }
      &:last-child {
        width: 29px;
      }
      &.guest {
        width: 163px;
      }
      &.booking-id {
        width: 178px;
      }
      &.check-time {
        width: 168px;
      }
      &.out-time {
        width: 167px;
      }
      &.status-width {
        width: 190px;
        span {
          cursor: unset;
        }
      }
      &.room-width {
        width: 135px;
      }
    }
    td {
      color: $description-color;
      font-size: $base-font - 1;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
      vertical-align: middle;
      padding: 11.1px 0 10px 0;
      border: solid $active-status-bg;
      border-width: 1px 0 1px 0;
      &:first-child {
        padding-left: 26px;
        border-width: 1px 0 1px 1px;
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-width: 1px 1px 1px 0;
        border-radius: 0px 2px 2px 0;
        position: relative;
      }
      .visiter-name {
        display: block;
        color: $menu-color;
        font-size: $base-font - 1;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 1px;
        cursor: pointer;
      }
      .visiter-mobile {
        display: block;
        color: $description-color;
        font-size: $base-font - 2;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 15px;
      }
      .room-number {
        color: $menu-color;
        font-size: $base-font + 2;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
      }
      .more-info {
        margin-top: 4px;
        .moreinfo-icon {
          width: 24px;
          height: 24px;
          text-align: center;
          position: relative;
          margin-top: -6px;
          display: flex;
          cursor: pointer;
          z-index: 0;
          align-items: center;
          justify-content: center;
          &.disabled {
            opacity: 0.2;
            pointer-events: none;
            cursor: not-allowed;
          }
          &.active {
            z-index: 2;
          }
        }
      }
    }
  }
}

.sorting-icons {
  margin-left: 2px;
  width: 8px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.status-wrapper {
  position: relative;
  .status-click-wrap {
    width: 137px;
  }
  .booking-status,
  .sms-delivered,
  .check-progress {
    width: 155px;
    border: 1px solid $booking-border;
    opacity: 0.5;
    border-radius: 2px;
    background-color: $booking-bg;
    height: 39px;
  }
  .sms-delivered {
    background-color: $delivered-bg;
  }
  .check-progress {
    border: 1px solid $progress-border;
    background-color: $progress-bg;
  }
  .status-check {
    @include pos-abs(6px, auto, auto, 11px);
    &.key-sent {
      top: 50%;
      @include transform(translateY(-50%));
    }
    .confirmation {
      color: $booking-confirmation;
      font-family: $font-stack;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      text-transform: capitalize;
      display: block;
      opacity: 1;
      &.delivered-text {
        color: $delivered-color;
      }
      &.progress-text {
        color: $progress-color;
      }
    }
    .informatory {
      color: #7f7f7f;
      font-family: $font-stack;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 13px;
      display: block;
      opacity: 1;
    }
  }
}
