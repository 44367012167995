@import "src/app/common/utils/scss/main.scss";
.loader-wrap {
    @include pos-abs(0, auto, auto, 0);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.resend-loader {
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    &.active {
        @include pos-fix(0, auto, auto, 0);
    }
}

.flex-property {
    flex-direction: column;
}

.margin {
    margin-top: 20px;
    color: white;
}