@import "src/app/common/utils/scss/main.scss";
.tabs-wrapper {
  padding: 26px 0 29px 0;
  h1 {
    padding: 0 38px;
  }
}

.tabbing-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding: 0 40px 0 40px;
}

.tabs-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  li {
    margin-right: 1px;
    position: relative;
    width: 180px;
    text-align: center;
    background: $tabs-bg;
    border-radius: 4px 4px 0 0;
    &.activeOverlay {
      position: absolute;
      width: 180px;
      height: 100%;
      border-radius: 4px 4px 0 0;
      transition: all 0.3s ease-in-out;
      background: linear-gradient(
        45deg,
        $tabs-gradient 0%,
        $tabs-gradient-sec 100%
      );
      z-index: 2;
    }
    &.sliding-list {
      background: linear-gradient(
        45deg,
        $tabs-gradient 0%,
        $tabs-gradient-sec 100%
      );
      @include pos-abs(0, auto, auto, 0);
      height: 100%;
      width: 100%;
      z-index: 2;
    }
    a {
      color: $tabs-color;
      font-size: $base-font;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      padding: 10px 25.1px 9px;
      transition: all 0.5s ease-in-out;
      position: relative;
      text-orientation: sideways;
      text-rendering: optimizeSpeed;
      &.active {
        // background: linear-gradient(45deg, $tabs-gradient 0%, $tabs-gradient-sec 100%);
        color: $color-white;
        z-index: 2;
        transition: all 0.5s ease-in-out;
        span {
          color: $color-white;
          position: relative;
          z-index: 3;
        }
        // &:after {
        //     content: '';
        //     @include pos-abs(0, auto, auto, 0);
        //     background: linear-gradient(45deg, $tabs-gradient 0%, $tabs-gradient-sec 100%);
        //     height: 100%;
        //     width: 100%;
        //     border-radius: 4px 4px 0 0;
        //     z-index: 2;
        //     // transition: all 0.5s ease-in-out;
        //     animation-name: example;
        //     animation-duration: 1s;
        //     //animation-iteration-count: infinite;
        // }
      }
    }
  }
}

@keyframes example {
  0% {
    left: 0px;
  }
  100% {
    left: 100px;
  }
}

.change-pass {
  color: $menu-color;
  font-size: $base-font - 4;
  font-weight: 600;
  letter-spacing: 0.42px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 0 -2px 0 0;
  .arrow-icon {
    margin-left: 5px;
  }
}

.date-picker-container {
  display: flex;
  flex-direction: row;

  //background: #ffe084;
  gap: 40px;
  width: 100%;
}
.date-picker-item {
  flex-direction: row;
  //background: red;
  cursor: pointer;
}

.date-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.date-picker-label {
  color: #4e4e4e;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  display: block;
  font-family: "Poppins", sans-serif;
}
.date-picker-date {
  font-size: 16px;
  font-weight: 500;
  padding-top: 5px;
}
.date-picker-calendar {
  position: absolute !important;
  margin-top: 3px !important;
  z-index: 99;
  --theme-color: #ffe084 !important; /* violet color */
  --selected-date-color: #000000 !important; /* light-gray color */
}

.date-picker-title {
  margin-bottom: 7px;
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-picker-icon {
  padding-right: 20px;
}
