@import "src/app/common/utils/scss/main.scss";
.btn-wrap {
  text-align: right;
  transition: all 0.3s;
  transition-timing-function: ease-in;
  .black-btn {
    position: relative;
    height: 46px;
    width: 120px;
    padding: 0;
    @include transition(all 0.3s ease-in);
    &.loading {
      @include transition(all 0.3s ease-in);
      padding-right: 25px;
      & .ld.ld-spin {
        visibility: visible;
        opacity: 1;
        z-index: auto;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.loading {
  pointer-events: none;
}

.ld.ld-spin {
  position: absolute;
  width: 17px;
  height: 17px;
  margin: -1px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  transition-timing-function: ease-in;
  top: 0;
  bottom: 0;
  margin: auto;
  left: auto;
  right: 12px;
  color: #fff;
  animation: ld-spin 1s infinite linear;

  &:after {
    content: " ";
    display: block;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
    transform-origin: 0 0;
    /* transform: translateZ(0) scale(0.5); */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 50%;
    border: 2px solid #000;
    border-left-color: transparent;
  }
}

@keyframes ld-spin {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ld.ld-spin {
  animation: ld-spin 1s infinite linear;
}
