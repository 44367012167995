@import "src/app/common/utils/scss/main.scss";
.form-field {
  margin-bottom: 31px;
  &:last-child {
    margin-bottom: 29px;
  }
  .field-title {
    color: $label-color;
    font-size: $base-font - 4px;
    font-weight: 300;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 7px;
    display: block;
    font-family: $font-stack;
  }
  .input-currency-field {
    height: 45px;
    width: 120px;
    color: $label-color;
    padding: 5px 10px;
    font-size: $base-font + 2px;
    border: 1px solid $input-border-color;
    @include border-radius(4px);
    background-color: $color-white;
    font-family: $font-stack;
    text-align: end;
  }
}
.field-wrap {
  position: relative;
}
.error-wrap {
  color: $error-text;
  margin-top: 6px;
  @include pos-abs(43px, auto, auto, 0);
  font-size: $base-font - 2;
}
