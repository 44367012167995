.geofencing-header-main-cont{  
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
}

.geofencing-header-left-cont, .geofencing-header-right-cont{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  li + li{
    margin-left: 1rem;
  }
}

.geofencing-header-view-option{
  padding: .5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #000;
  background-color: #ddd;
  svg{
    pointer-events: none;
  }
}

.geofencing-header-view-option[data-selected=true]{
  background-color: #ffd865;
}

.geofencing-header-poi-cont{
  display: flex;
  margin-top: 1rem;
  li + li{
    margin-left: .5rem;
  }
  label{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  img{
    height: 35px;
    width: 35px;
  }
  input[type=checkbox]{
    cursor: pointer;
  }
}