@import "src/app/common/utils/scss/main.scss";
.sidebar-wrapper {
  position: fixed;
  background-color: $sidebar-bg;
  box-shadow: 0 2px 19px 0 rgba(206, 206, 206, 0.15);
  //min-width: 210px !important;
  width:181px !important;
  min-height: calc(100vh - 71px);
  //height: 100vh;
  border-right: 1px solid $border-color;
  padding: 69px 0 0 0;
}

.sidebar-menu {
  li {
    a {
      color: $menu-color;
      font-family: $font-stack;
      font-size: $base-font - 1;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10.7px 16px 10px 20px;
      text-transform: capitalize;
      border-bottom: 1px solid $border-color;
      &.active {
        opacity: 1;
      }
      .total-reservations {
        background: $active-status-bg;
        height: 25px;
        width: 25px;
        @include border-radius(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $active-status-color;
        font-size: $base-font - 2;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }
    }
    &:last-child {
      a {
        border-bottom: 0;
        padding-top: 29px;
      }
    }
  }
}

.sidebar-icon-badge {
  background-color: #ff4646;
  font-size: 10px;
  font-weight: 400;
  color: white;
  text-align: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  padding-top: 1px;
  left: 0px; /* changed */
}
