.app-dataset-wrapper{
  //background-color: rgba(128, 128, 128, .2);
  padding: 2rem;
  width: 100%;
  min-height: 300px;
  border-radius: 16px;
  margin-bottom: 2rem;
  h2{
    margin: 0;
    font-weight: bold;
    text-align: left;
  }          
  p{
    color: gray;
    font-size: 14px;
    margin-bottom: 1rem;
  }    
}