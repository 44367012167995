/* Eric Meyer's Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-size: $base-font;
  font-family: $font-stack;
  color: $color-black;
}

ol,
ul {
  list-style: none;
}

/* Font Face */

/* Global Typography */

*,
:before,
:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

/* Global Headings */

h1 {
  color: $active-status-color;
  font-size: $base-font + 11;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 11px;
}

h2 {
  color: $heading-color;
  font-size: $base-font + 6;
  font-weight: 600;
  letter-spacing: 1.11px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 34px;
}

/* General Text Formatting */

p {
}

small {
  font-size: 85.71429%;
  line-height: 1.75;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

/* Global Link style */

a {
  color: $color-black;
  text-decoration: none;
  outline: none;
  display: inline-block;
}

// Global Classes
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Global Button Styles */

button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.black-btn {
  @include border-radius(4px);
  background-color: $btn-black;
  text-transform: capitalize;
  color: $menu-color;
  font-size: $base-font;
  font-weight: 600;
  padding: 11.6px 37px 11.6px;
  letter-spacing: 0px;
  font-family: $font-stack;
}

.cancel-item-btn {
  @include border-radius(4px);
  background-color: #f4f4f7;
  //border: 1px solid #6F6F6F;
  text-transform: capitalize;
  color: $menu-color;
  font-size: $base-font;
  font-weight: 600;
  padding: 11.6px 37px 11.6px;
  letter-spacing: 0px;
  font-family: $font-stack;
}

.cancel-btn {
  color: $cancel-btn;
  font-size: $base-font;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  font-family: $font-stack;
  &:disabled {
    color: #dddddd;
  }
}

/* Global style */

.container {
  max-width: 1183px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

input {
  &:focus {
    outline: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// body {
//     &.disable-scrolling {
//         overflow: hidden !important;
//         height: 100%;
//     }
// }
#root {
  position: relative;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  @include pos-abs(50%, auto, auto, -15px);
  @include transform(translateY(-50%));
  height: 43px;
  width: 43px;
  background-color: #f4f4f7;
  @include border-radius(50%);
  font-size: 0;
  &:enabled {
    height: 43px;
    width: 43px;
    background-color: #f4f4f7;
    @include border-radius(50%);
  }
  &:after {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    transform: translate(-50%, -50%) rotate(180deg);
    background: url(../../../assets/images/arrow-big.svg) no-repeat;
    height: 10.5px;
    width: 14px;
  }
}

.react-calendar__navigation__next-button {
  left: auto;
  right: -15px;
  &:after {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    transform: translate(-50%, -50%) rotate(0deg);
    //background: url(../../../assets/images/arrow-big.svg) no-repeat;
    height: 10.5px;
    width: 14px;
  }
}

.sc-message--text {
  
  a {
    color:#fff;
    word-break: break-all;
  }
}
