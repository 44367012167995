@import "src/app/common/utils/scss/main.scss";
.admin-login-wrapper {
  background: url(../../../assets/images/hotel-background.png) no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: "";
    @include pos-abs(0, auto, auto, 0);
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
}

.login-form {
  .black-btn {
    height: 46px;
    width: 114px;
    padding: 0;
    @include transition(all 0.3s ease-in);
    &.loading {
      @include transition(all 0.3s ease-in);
      padding-right: 25px;
      & .ld.ld-spin {
        visibility: visible;
        opacity: 1;
        z-index: auto;
      }
    }
  }
}

.logo-img {
  display: block;
  margin-right: -4px;
}

.user-login {
  width: 470px;
  height: 423px;
  background: $color-white;
  @include border-radius(8px);
  padding: 54px 99px 40px 100px;
  position: relative;
  z-index: 1;
}

.hotel-logo {
  text-align: center;
  margin-bottom: 42px;
  img {
    width: 55px;
    margin: 0 0 0 1px;
  }
}

// Responsive styles
@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .admin-login-wrapper {
    justify-content: flex-end;
  }
  .user-login {
    margin-right: 14.9%;
  }
}

// body {
//     background: url(../../../../../assets/images/1\ admin.png) no-repeat center top;
// }
