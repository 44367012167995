.sources-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 200;
    gap:40px;
    margin-left:40px;
   
}
.source-item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 300px;
    padding:20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; 
    
}
.source-item:hover{
    background-color: #fafafa;
    border: 1px solid #999;
}

.subtitle{
    margin-left:40px;
}

.upload-wrapper{
    margin:0px 40px 40px 40px;
}

.import-dropzone-container {
    font-size: 16px;
  }
  
  .import-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px;
    border: 2px solid #e4e4e4;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
  
  .import-dropzone:hover {
    border: 2px solid #ffd865;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fffbee;
    transition: border 0.24s ease-in-out;
  }

  .datatable-wrapper{
    margin:0px 40px 40px 40px;
  }

  .buttons-wrapper{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    gap:10px;
    margin:0px 40px 40px 40px;
  }

  .success-wrapper{
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
  }
  .success-icon{
   color: #69C656;
   size:100px;
  }

  .success-text{
    font-size: 20px;
    line-height: 24px;
    color: #6F6F6F;
    text-align:center;
  }

  