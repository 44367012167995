@import "src/app/common/utils/scss/main.scss";

.document-viewer-wrap {
  @include pos-fix(0, 0, auto, auto);
  height: 100vh;
  width: 57.6%;
  background: $color-white;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.25);
  padding: 40px 60px 48px 60px;
  @include transition(all 0.7s ease-in-out);
  overflow-y: auto;
  z-index: 3;
  animation-name: example2;
  animation-duration: 1s;
  
}

@keyframes example {
  from {
    @include transform(translateX(202%));
  }
  to {
    @include transform(translateX(0));
  }
}

.user-name {
  margin-bottom: 24px;
}

.room-card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  .room-details {
    margin-right: 7px;
    .guest-name {
      letter-spacing: 0px;
    }
  }
  .guest-name {
    font-weight: 700;
    letter-spacing: 25.7px;
  }
}

.user-title {
  color: $label-color;
  font-size: $base-font - 4;
  letter-spacing: 0.56px;
  line-height: 13px;
  display: block;
  text-transform: uppercase;
}

.guest-name {
  color: $user-color;
  font-size: $base-font + 16;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  display: block;
  text-transform: capitalize;
}

.guest-details-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  .phone-number {
    width: 85px;
    .success-icon {
      margin-top: -2px;
      margin-left: 3px;
    }
  }
}

.guest-details {
  color: $user-color;
  font-size: $base-font - 1;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.photo-id {
  @include border-radius(8px);
  background-color: $color-white;
  box-shadow: 0 2px 104px 0 rgba(209, 209, 209, 0.5);
  height: 294px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;

  .image-wrapper {
    height: 266px;
    width: 419px;
    border: 1px dashed $calendar-color;
    img {
      height: 264px;
      width: 419px;
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .black-btn {
    padding: 15px 22.2px 14px;
    margin-left: 17px;
  }
}

.noCredit-found {
  color: $user-color;
  font-size: $base-font - 1;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  .warning-icon {
    margin-right: 6px;
  }
}


@media only screen and (min-width: 1300px) {
  .document-viewer-wrap {
    padding: 40px 87px 28px 103px;
  }
}

.sc-user-input--file-icon-wrapper {
  visibility: hidden;
}

.sc-header {
  background: #000000 !important;
}

.sc-launcher {
  background: #000000 !important;
}

.sc-header--close-button:hover {
  background: #1b1b1b !important;
}

.sc-new-messages-count {
  background-color: #4e8cff !important;
  font-weight: 400 !important;
  color: white !important;
}
.viewdocument-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-verified {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .document-verified-text {
    color: $label-color;
    font-size: $base-font - 1;
    letter-spacing: 0.56px;
    line-height: 13px;
    display: block;
    text-transform: capitalize;
  }
}
