@import "src/app/common/utils/scss/main.scss";
.form {
  display: flex;
  flex-direction: column;
}
.form-field {
  margin-bottom: 31px;
  &:last-child {
    margin-bottom: 29px;
  }
  .field-title {
    color: $label-color;
    font-size: $base-font - 4px;
    font-weight: 300;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 7px;
    display: block;
    font-family: $font-stack;
  }
  &.password-field {
    margin-bottom: 25px;
    & input {
      -webkit-text-security: disc;
    }
  }
  .input-field {
    height: 45px;
    width: 100%;
    color: $label-color;
    padding: 5px 10px;
    font-size: $base-font + 2px;
    border: 1px solid $input-border-color;
    @include border-radius(4px);
    background-color: $color-white;
    font-family: $font-stack;
    .disabled {
      background-color: gray;
    }
  }
}

.field-wrap {
  position: relative;
}

.error-wrap {
  color: $error-text;
  margin-top: 6px;
  @include pos-abs(43px, auto, auto, 0);
  font-size: $base-font - 2;
}

.FormRowInput {
  height: 45px;
  width: 100%;
  color: $label-color;
  padding: 15px 10px;
  font-size: $base-font + 4px;
  font-weight: 600;
  border: 1px solid $input-border-color;
  @include border-radius(4px);
  background-color: $color-white;
  font-family: $font-stack;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #000000;
}

.ErrorMessage {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
}

.ErrorMessage svg {
  margin-right: 10px;
}

//.FormGroup {
//  margin: 0 15px 20px;
//  padding: 0;
//  border-style: none;
//  will-change: opacity, transform;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #829fff;
//  border-radius: 4px;
//}
//
//.FormRow {
//  display: -ms-flexbox;
//  display: flex;
//  -ms-flex-align: center;
//  align-items: center;
//  margin-left: 15px;
//  border-top: 1px solid #819efc;
//}
//
//.FormRow:first-child {
//  border-top: none;
//}
//
//.FormRowLabel {
//  width: 15%;
//  min-width: 70px;
//  padding: 11px 0;
//  color: #c0c0c0;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}
//
//@keyframes void-animation-out {
//  0%,
//  to {
//    opacity: 1;
//  }
//}
//.FormRowInput:-webkit-autofill {
//  -webkit-text-fill-color: #fce883;
//  /* Hack to hide the default webkit autofill */
//  transition: background-color 100000000s;
//  animation: 1ms void-animation-out;
//}
//
//.FormRowInput {
//  font-size: 16px;
//  width: 100%;
//  padding: 11px 15px 11px 0;
//  color: #000;
//  background-color: transparent;
//  animation: 1ms void-animation-out;
//}
//
//.FormRowInput::placeholder {
//  color: #000000;
//}
//
//.StripeElement--webkit-autofill {
//  background: transparent !important;
//}
//
//.StripeElement {
//  width: 100%;
//  padding: 11px 15px 11px 0;
//}
//
//.SubmitButton {
//  display: block;
//  font-size: 16px;
//  width: calc(100% - 30px);
//  height: 40px;
//  margin: 5px 15px 0;
//  background-color: #c0c0c0;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #c0c0c0;
//  border-radius: 4px;
//  color: #fff;
//  font-weight: 600;
//  cursor: pointer;
//  transition: all 100ms ease-in-out;
//  will-change: transform, background-color, box-shadow;
//}
//
//.SubmitButton:active {
//  background-color: #c0c0c0;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #c0c0c0;
//  transform: scale(0.99);
//}
//
//.SubmitButton.SubmitButton--error {
//  transform: translateY(15px);
//}
//.SubmitButton.SubmitButton--error:active {
//  transform: scale(0.99) translateY(15px);
//}
//
//.SubmitButton:disabled {
//  opacity: 0.5;
//  cursor: default;
//  background-color: #7795f8;
//  box-shadow: none;
//}

//.Result {
//  margin-top: 50px;
//  text-align: center;
//  animation: fade 200ms ease-out;
//}
//
//.ResultTitle {
//  color: #fff;
//  font-weight: 500;
//  margin-bottom: 8px;
//  font-size: 17px;
//  text-align: center;
//}
//
//.ResultMessage {
//  color: #9cdbff;
//  font-size: 14px;
//  font-weight: 400;
//  margin-bottom: 25px;
//  line-height: 1.6em;
//  text-align: center;
//}
//
//.ResetButton {
//  border: 0;
//  cursor: pointer;
//  background: transparent;
//}

#__lpform_cardnumber_icon {
  background-color: #000 !important;
}
